import React, { useEffect, useState } from 'react';
import '../components/presentational/fonts/marianne/stylesheet.css';
import '../components/presentational/fonts/dinot/dinot.css';
import '../components/presentational/fonts/klinic-slab/klinicSlab.css';

import GlobalStyles from '../components/presentational/GlobalStyles';
import 'isomorphic-unfetch';
import Head from 'next/head';
import favicon from '../components/presentational/img/favicon.ico';
import { Router, useRouter } from 'next/router';
import * as Sentry from '@sentry/node';
import getConfig from 'next/config';
import { RewriteFrames } from '@sentry/integrations';
import { register, workbox } from '../serviceWorker/registerServiceWorker';
import {
  NetworkStatusContext,
  useNetworkStatusListener,
} from '../components/app/networkStatus/networkStatusHook';
import TarteAuCitron from '../components/refonte2024/app/TarteAuCitron';

const config = getConfig();
const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
const env = config.publicRuntimeConfig.ENV;
const commitSha = config.publicRuntimeConfig.COMMIT_SHA;
const sentryDsn = config.publicRuntimeConfig.SENTRY_DSN;
const plausibleUrl = config.publicRuntimeConfig.PLAUSIBLE_URL;

if (typeof window !== 'undefined') {
  Sentry.init({
    enabled: env && env !== 'dev',
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: sentryDsn,
    environment: env,
    release: commitSha,
    ignoreErrors: ['_paq is not defined', "Can't find variable: _paq"],
    beforeSend(event, hint) {
      if (hint.originalException?.message === 'Failed to fetch') {
        return null;
      }
      return event;
    },
    /**
     * `beforeBreadcrumb` works like a filter for UI click events.
     * It checks if the breadcrumb is a click event, then grabs the text of the clicked element (button, link, etc.).
     * If the text exists, it appends it to the breadcrumb message, adding context to the user interaction.
     */
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event;

        if (target instanceof HTMLElement) {
          const text =
            target.innerText || target.textContent || target.ariaLabel;

          if (text) {
            breadcrumb.message = `${breadcrumb.message}: ${text}`;
          }
        }
      }
      return breadcrumb;
    },
  });
}

if (config.publicRuntimeConfig.ENABLE_SERVICE_WORKER) {
  if (typeof window !== 'undefined') {
    register();
  }
}

function MyApp(props) {
  const { Component, pageProps } = props;
  const router = useRouter();

  const networkStatus = useNetworkStatusListener(pageProps?.isOfflinePage);

  useEffect(() => {
    if (!config.publicRuntimeConfig.ENABLE_SERVICE_WORKER) {
      return;
    }

    const script = document.getElementById('__NEXT_DATA__');
    const scriptBase = document.getElementById('__NEXT_BASE_SCRIPTS__');

    const sendPageProps = url => {
      if (!workbox) {
        return;
      }

      workbox.messageSW({
        type: 'POPULATE_HTML_CACHE',
        url,
        pageProps: pageProps,
        page: router.route,
        query: router.query,
        nextData: script.innerText,
        nextBaseScripts: scriptBase.innerText,
        chunkScripts: __BUILD_MANIFEST?.[router.route],
      });
    };

    router.events.on('routeChangeComplete', sendPageProps);

    return () => {
      router.events.off('routeChangeComplete', sendPageProps);
    };
  }, [pageProps, router]);

  useEffect(() => {
    if (!config.publicRuntimeConfig.ENABLE_SERVICE_WORKER) {
      return;
    }
    const forceRedirectOnRouteChangeErrorOffline = (err, url) => {
      if (!networkStatus.isOnline) {
        window.location.href = url;
      }
    };

    router.events.on(
      'routeChangeError',
      forceRedirectOnRouteChangeErrorOffline
    );

    return () => {
      router.events.off(
        'routeChangeError',
        forceRedirectOnRouteChangeErrorOffline
      );
    };
  }, [router, networkStatus.isOnline]);

  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    const sendPageview = () => {
      let contentCountryCode = pageProps?.contentCountryCode
        ? pageProps.contentCountryCode
        : router.query?.country;
      if (!contentCountryCode?.match(/^[a-z]{2}$/)) {
        contentCountryCode = null;
      }
      if (typeof plausible !== 'undefined' && pageProps) {
        plausible('pageview', { props: { country: contentCountryCode } });
      }

      if (typeof _paq !== 'undefined' && pageProps) {
        // https://github.com/SocialGouv/matomo-next/blob/master/src/index.ts#L169
        setTimeout(() => {
          _paq.push(['setCustomUrl', router.asPath]);
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['setReferrerUrl', referrer]);
          _paq.push(['trackPageView']);
          setReferrer(router.asPath);
        }, 0);
      }
    };
    Router.events.on('routeChangeComplete', sendPageview);
    return () => {
      Router.events.off('routeChangeComplete', sendPageview);
    };
  }, [router.query, router.asPath, pageProps?.contentCountryCode]);

  return (
    <>
      <Head>
        <link
          as="script"
          rel="preload"
          href={plausibleUrl}
          crossOrigin="anonymous"
        />
        <meta name="robots" content="index, follow" key="robots" />
        <link rel="icon" type="image/x-icon" href={favicon.src} />

        {config.publicRuntimeConfig.ENABLE_SERVICE_WORKER && (
          <>
            <link rel="manifest" href="/manifest/manifest.webmanifest" />
            <link rel="apple-touch-icon" href="/manifest/192x192.png" />
            <meta name="theme-color" content="#058bc4" />
          </>
        )}

        <meta property="og:type" content="article" key="og_type" />
        <meta property="og:locale" content="fr_FR" key="og_locale" />
        <meta property="og:site_name" content="IFprofs" key="og_site_name" />
        <meta name="twitter:card" content="summary" key="twitter_card" />
        <meta
          name="twitter:url"
          content={`https://www.ifprofs.org${router.asPath}`}
          key="twitter_url"
        />
        <GlobalStyles />
      </Head>
      <TarteAuCitron />

      <NetworkStatusContext.Provider value={networkStatus}>
        <Component {...pageProps} err={props.err} />
      </NetworkStatusContext.Provider>
    </>
  );
}

export default MyApp;
